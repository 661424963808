//variables
//@import "../base/variables";

.panel {
  border-radius: $global-radius;

  &.highlight {
    background-color: $highlight-color;
    -webkit-box-shadow: 0 0 6px 1px rgba(201,201,201,.5);
    box-shadow: 0 0 6px 1px rgba(201,201,201,.5);
    border: 0;
  }

  &.info {
    background-color: $info-color;
    -webkit-box-shadow: 0 0 6px 1px rgba(201,201,201,.5);
    box-shadow: 0 0 6px 1px rgba(201,201,201,.5);
  }

  &.highlight-button {
    &:hover {
      background-color: lighten($primary-color, 60);
      -webkit-box-shadow: 0 0 6px 1px rgba(201,201,201,.5);
      box-shadow: 0 0 6px 1px rgba(201,201,201,.5);
    }
  }
}

