// Allows the use of rem-calc() or lower-bound()
@import "../foundation/functions";

////////////////////////////////
//
// FOUNDATION SETTING OVERRIDES
//
////////////////////////////////

//
// MEDIA QUERY RANGES
//
$small-range: (0em, 900px);
$medium-range: (901px, 70em);
$large-range: (70.063em, 90em);
$screen: "only screen";
$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})";

//
// Grid
// - - - - - - - - - - - - - - - - - - - - - - - - -

$row-width: rem-calc(1000);
$column-gutter: rem-calc(30);

//
// GLOBAL
//
// c. Global
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use these to define default font stacks
$font-family-sans-serif: "Roboto", Arial, sans-serif;
// $font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif;
// $font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;

// We use these to define default font weights
$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

// We use these as default colors throughout
$primary-color: #161850; //Maritz Indigo
$secondary-color: #e7e7e7;
$alert-color: #f04124;
$success-color: #98C23D; //Maritz Lime
$warning-color: #FB8319;
$info-color: #C8E4F2;


$white: #FFFFFF;
$jet: #303237;
$black: #000000;

// CUSTOM COLORS
//
$accent-color: #5572A6; //Maritz Cadet Blue
$warning-color-dark: #dd6a04;
$primary-color-dark: darken($primary-color, 50%);
$dark-blue-background: #1c2668;
$highlight-color: #FFFFc9;

// We use these to control various global styles
$body-bg: $secondary-color;
$body-font-color: $jet;
// $body-font-family: $font-family-sans-serif;
// $body-font-weight: $font-weight-normal;
// $body-font-style: normal;

// We use this to control font-smoothing
// $font-smoothing: antialiased;

// We use these to control text direction settings
// $text-direction: ltr;
// $opposite-direction: right;
// $default-float: left;
// $last-child-float: $opposite-direction;

// We use these to make sure border radius matches unless we want it different.
$global-radius: 5px;
// $global-rounded: 1000px;

// We use these to control inset shadow shiny edges and depressions.
// $shiny-edge-size: 0 1px 0;
// $shiny-edge-color: rgba($white, .5);
// $shiny-edge-active-color: rgba($black, .2);

$h1-font-size: rem-calc(44);
$h2-font-size: rem-calc(37);
$h3-font-size: rem-calc(27);
$h4-font-size: rem-calc(23);
$h5-font-size: rem-calc(18);
$h6-font-size: 1rem;

// 04. Breadcrumbs
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use this to set the background color for the breadcrumb container.
$crumb-bg: scale-color($secondary-color, $lightness: 65%);

// We use these to set the padding around the breadcrumbs.
$crumb-padding: rem-calc(9 14 9);
$crumb-side-padding: rem-calc(12);

// We use these to control border styles.
$crumb-function-factor: -10%;
$crumb-border-size: 0;
$crumb-border-style: solid;
$crumb-border-color: scale-color($crumb-bg, $lightness: $crumb-function-factor);
$crumb-radius: $global-radius;

// We use these to set various text styles for breadcrumbs.
$crumb-font-size: rem-calc(14);
$crumb-font-color: $primary-color;
$crumb-font-color-current: $primary-color;
$crumb-font-color-unavailable: lighten($jet,30%);
$crumb-font-transform: none;
$crumb-link-decor: underline;

// We use these to control the slash between breadcrumbs
// $crumb-slash-color: $base;
// $crumb-slash: "/";
//
// BUTTONS
//
//$button-font-family: $header-font-family;
// We use these to build padding for buttons.
$button-tny: rem-calc(8);
$button-sml: rem-calc(12);
$button-med: rem-calc(16);
$button-lrg: rem-calc(18);

$button-font-tny: rem-calc(11);
$button-font-sml: rem-calc(13);
$button-font-med: rem-calc(16);
$button-font-lrg: rem-calc(20);

$button-margin-bottom: rem-calc(8);

$global-radius: 5px;
$global-rounded: 1000px;

// 11. Forms
$form-label-font-size: rem-calc(12);
$form-label-font-weight: $font-weight-bold;
$form-label-font-color: scale-color($black, $lightness: 30%);




// 16. Labels
// - - - - - - - - - - - - - - - - - - - - - - - - -

// $include-html-label-classes: $include-html-classes;

// We use these to style the labels
// $label-padding: rem-calc(4 8 6);
// $label-radius: $global-radius;

// We use these to style the label text
$label-font-sizing: rem-calc(12);
$label-font-weight: $font-weight-bold;
// $label-font-color: $oil;
// $label-font-color-alt: $white;
// $label-font-family: $body-font-family;


// TABLES
//
//$include-html-table-classes: false;

//
//Pricing Tables
// - - - - - - - - - - - - - - - - - - - - - - - - -

// $include-html-pricing-classes: $include-html-classes;

// We use this to control the border color
$price-table-border: solid 1px $secondary-color;

// We use this to control the bottom margin of the pricing table
// $price-table-margin-bottom: rem-calc(20);

// We use these to control the title styles
$price-title-bg: $white;
// $price-title-padding: rem-calc(15 20);
// $price-title-align: center;
$price-title-color: $jet;
$price-title-weight: $font-weight-normal;
$price-title-size: rem-calc(18);
$price-title-font-family: "Roboto Condensed", Arial, sans-serif;

// We use these to control the price styles
$price-money-bg: $white;
$price-money-padding: rem-calc(8 16);
// $price-money-align: center;
// $price-money-color: $oil;
// $price-money-weight: $font-weight-normal;
// $price-money-size: rem-calc(32);
// $price-money-font-family: $body-font-family;

// We use these to control the description styles
$price-bg: $white;
// $price-desc-color: $monsoon;
// $price-desc-padding: rem-calc(15);
// $price-desc-align: center;
// $price-desc-font-size: rem-calc(12);
// $price-desc-weight: $font-weight-normal;
// $price-desc-line-height: 1.4;
// $price-desc-bottom-border: dotted 1px $gainsboro;

// We use these to control the list item styles
// $price-item-color: $oil;
// $price-item-padding: rem-calc(15);
// $price-item-align: center;
// $price-item-font-size: rem-calc(14);
// $price-item-weight: $font-weight-normal;
// $price-item-bottom-border: dotted 1px $gainsboro;

// We use these to control the CTA area styles
// $price-cta-bg: $white;
// $price-cta-align: center;
// $price-cta-padding: rem-calc(20 20 0);
$price-money-color: $body-font-color;

//
// Reveal

// $include-html-reveal-classes: $include-html-classes;

// We use these to control the style of the reveal overlay.
// $reveal-overlay-bg: rgba(#000, .45);
// $reveal-overlay-bg-old: #000;

// We use these to control the style of the modal itself.
$reveal-modal-bg: #ffffff;
// $reveal-position-top: 50px;
// $reveal-default-width: 80%;
$reveal-modal-padding: rem-calc(20);
$reveal-position-top: rem-calc(20);
// $reveal-box-shadow: 0 0 10px rgba(#000,.4);

// We use these to style the reveal close button
$reveal-close-font-size: rem-calc(26);
$reveal-close-top: rem-calc(6);
$reveal-close-side: rem-calc(10);
$reveal-close-color: $primary-color;
// $reveal-close-weight: bold;

$reveal-radius: $global-radius;
$reveal-round: 1000px;


// TOP BAR
//
// Background color for the top bar
$topbar-bg-color: $white;
$topbar-bg: $topbar-bg-color;

// Height and margin
$topbar-height: 50px;
// $topbar-margin-bottom: 0;

// Controlling the styles for the title in the top bar
// $topbar-title-weight: $font-weight-normal;
// $topbar-title-font-size: rem-calc(17);

// Style the top bar dropdown elements
$topbar-dropdown-bg: $secondary-color;
$topbar-dropdown-link-color: $primary-color;
$topbar-dropdown-link-bg: $secondary-color;
// $topbar-dropdown-link-weight: $font-weight-normal;
// $topbar-dropdown-toggle-size: 5px;
$topbar-dropdown-toggle-color: $primary-color;
$topbar-dropdown-toggle-alpha: 1.0;

// Set the link colors and styles for top-level nav
$topbar-link-color: $primary-color;
$topbar-link-color-hover: $primary-color-dark;
$topbar-link-color-active: $primary-color;
$topbar-link-color-active-hover: $primary-color;
//$topbar-link-weight: $font-weight-normal;
$topbar-link-font-size: rem-calc(16);
$topbar-link-hover-lightness: -10%; // Darken by 10%
$topbar-link-bg: $topbar-bg;
//$topbar-link-bg-color-hover: $charcoal;
$topbar-link-bg-hover: $info-color;
$topbar-link-bg-active: $primary-color;
$topbar-link-bg-active-hover: scale-color($primary-color, $lightness: -14%);
$topbar-link-font-family: "Roboto Condensed", Arial, sans-serif;
// $topbar-link-text-transform: none;
// $topbar-link-padding: $topbar-height / 3;
// $topbar-back-link-size: $h5-font-size;
// $topbar-link-dropdown-padding: 20px;

// $topbar-button-font-size: 0.75rem;
// $topbar-button-top: 7px;

// $topbar-dropdown-label-color: $monsoon;
// $topbar-dropdown-label-text-transform: uppercase;
// $topbar-dropdown-label-font-weight: $font-weight-bold;
// $topbar-dropdown-label-font-size: rem-calc(10);
// $topbar-dropdown-label-bg: $oil;

// Top menu icon styles
// $topbar-menu-link-transform: uppercase;
// $topbar-menu-link-font-size: rem-calc(14);
// $topbar-menu-link-weight: $font-weight-bold;
$topbar-menu-link-color: $primary-color-dark;
$topbar-menu-icon-color: $primary-color-dark;
$topbar-menu-link-color-toggled: $primary-color-dark;
$topbar-menu-icon-color-toggled: $primary-color-dark;

// Transitions and breakpoint styles
// $topbar-transition-speed: 300ms;
// Using rem-calc for the below breakpoint causes issues with top bar
// $topbar-breakpoint: #{lower-bound($medium-range)}; // Change to 9999px for always mobile layout
$topbar-media-query: $large-up;

// Divider Styles
// $topbar-divider-border-bottom: solid 1px  scale-color($topbar-bg-color, $lightness: 13%);
// $topbar-divider-border-top: solid 1px scale-color($topbar-bg-color, $lightness: -50%);

// Sticky Class
// $topbar-sticky-class: ".sticky";
// $topbar-arrows: true; //Set false to remove the triangle icon from the menu item

//
// FORMS
//
$fieldset-padding: rem-calc(14 20);
$fieldset-margin: rem-calc(0 0 18 0);

//
// PAGINATION
//
$pagination-height: inherit;


// e. Typography
// - - - - - - - - - - - - - - - - - - - - - - - - -

// $include-html-type-classes: $include-html-classes;

// We use these to control header font styles
$header-font-family: "Roboto Condensed", Arial, sans-serif;
// $header-font-weight: $font-weight-normal;
// $header-font-style: normal;
// $header-font-color: $jet;
// $header-line-height: 1.4;
// $header-top-margin: .2rem;
// $header-bottom-margin: .5rem;
// $header-text-rendering: optimizeLegibility;

// We use these to control header font sizes
// $h1-font-size: rem-calc(44);
// $h2-font-size: rem-calc(37);
// $h3-font-size: rem-calc(27);
// $h4-font-size: rem-calc(23);
// $h5-font-size: rem-calc(18);
// $h6-font-size: 1rem;

// We use these to control header size reduction on small screens
// $h1-font-reduction: rem-calc(10) !default;
// $h2-font-reduction: rem-calc(10) !default;
// $h3-font-reduction: rem-calc(5) !default;
// $h4-font-reduction: rem-calc(5) !default;
// $h5-font-reduction: 0 !default;
// $h6-font-reduction: 0 !default;

// These control how subheaders are styled.
// $subheader-line-height: 1.4;
// $subheader-font-color: scale-color($header-font-color, $lightness: 35%);
// $subheader-font-weight: $font-weight-normal;
// $subheader-top-margin: .2rem;
$subheader-bottom-margin: 0;

// A general <small> styling
// $small-font-size: 60%;
// $small-font-color: scale-color($header-font-color, $lightness: 35%);

// We use these to style paragraphs
// $paragraph-font-family: inherit;
// $paragraph-font-weight: $font-weight-normal;
// $paragraph-font-size: 1rem;
// $paragraph-line-height: 1.6;
// $paragraph-margin-bottom: rem-calc(20);
// $paragraph-aside-font-size: rem-calc(14);
// $paragraph-aside-line-height: 1.35;
// $paragraph-aside-font-style: italic;
// $paragraph-text-rendering: optimizeLegibility;

// We use these to style <code> tags
// $code-color: $oil;
// $code-font-family: $font-family-monospace;
// $code-font-weight: $font-weight-normal;
// $code-background-color: scale-color($secondary-color, $lightness: 70%);
// $code-border-size: 1px;
// $code-border-style: solid;
// $code-border-color: scale-color($code-background-color, $lightness: -10%);
// $code-padding: rem-calc(2) rem-calc(5) rem-calc(1);

// We use these to style anchors
// $anchor-text-decoration: none;
// $anchor-text-decoration-hover: none;
$anchor-font-color: $primary-color;
// $anchor-font-color-hover: scale-color($primary-color, $lightness: -14%);

// We use these to style the <hr> element
// $hr-border-width: 1px;
// $hr-border-style: solid;
// $hr-border-color: $gainsboro;
// $hr-margin: rem-calc(20);

// We use these to style lists
// $list-font-family: $paragraph-font-family;
// $list-font-size: $paragraph-font-size;
// $list-line-height: $paragraph-line-height;
// $list-margin-bottom: $paragraph-margin-bottom;
// $list-style-position: outside;
$list-side-margin: 0;
// $list-ordered-side-margin: 1.4rem;
// $list-side-margin-no-bullet: 0;
// $list-nested-margin: rem-calc(20);
// $definition-list-header-weight: $font-weight-bold;
// $definition-list-header-margin-bottom: .3rem;
// $definition-list-margin-bottom: rem-calc(12);

// We use these to style blockquotes
// $blockquote-font-color: scale-color($header-font-color, $lightness: 35%);
// $blockquote-padding: rem-calc(9 20 0 19);
// $blockquote-border: 1px solid $gainsboro;
// $blockquote-cite-font-size: rem-calc(13);
// $blockquote-cite-font-color: scale-color($header-font-color, $lightness: 23%);
// $blockquote-cite-link-color: $blockquote-cite-font-color;

// Acronym styles
// $acronym-underline: 1px dotted $gainsboro;

// We use these to control padding and margin
// $microformat-padding: rem-calc(10 12);
// $microformat-margin: rem-calc(0 0 20 0);

// We use these to control the border styles
// $microformat-border-width: 1px;
// $microformat-border-style: solid;
// $microformat-border-color: $gainsboro;

// We use these to control full name font styles
// $microformat-fullname-font-weight: $font-weight-bold;
// $microformat-fullname-font-size: rem-calc(15);

// We use this to control the summary font styles
// $microformat-summary-font-weight: $font-weight-bold;

// We use this to control abbr padding
// $microformat-abbr-padding: rem-calc(0 1);

// We use this to control abbr font styles
// $microformat-abbr-font-weight: $font-weight-bold;
// $microformat-abbr-font-decoration: none;

// 01. Accordion
// - - - - - - - - - - - - - - - - - - - - - - - - -

// $include-html-accordion-classes: $include-html-classes;

$accordion-navigation-padding: rem-calc(12);
// $accordion-navigation-bg-color: $silver  ;
// $accordion-navigation-hover-bg-color: scale-color($accordion-navigation-bg-color, $lightness: -5%);
// $accordion-navigation-active-bg-color: scale-color($accordion-navigation-bg-color, $lightness: -3%);
// $accordion-navigation-font-color: $jet;
// $accordion-navigation-font-size: rem-calc(16);
// $accordion-navigation-font-family: $body-font-family;

// $accordion-content-padding: $column-gutter/2;
// $accordion-content-active-bg-color: $white;

// 02. Alert Boxes
// - - - - - - - - - - - - - - - - - - - - - - - - -

// $include-html-alert-classes: $include-html-classes;

// We use this to control alert padding.
// $alert-padding-top: rem-calc(14);
// $alert-padding-default-float: $alert-padding-top;
// $alert-padding-opposite-direction: $alert-padding-top + rem-calc(10);
// $alert-padding-bottom: $alert-padding-top;

// We use these to control text style.
// $alert-font-weight: $font-weight-normal;
// $alert-font-size: rem-calc(13);
// $alert-font-color: $white;
// $alert-font-color-alt: scale-color($secondary-color, $lightness: -66%);

// We use this for close hover effect.
// $alert-function-factor: -14%;

// We use these to control border styles.
// $alert-border-style: solid;
// $alert-border-width: 1px;
// $alert-border-color: scale-color($primary-color, $lightness: $alert-function-factor);
// $alert-bottom-margin: rem-calc(20);

// We use these to style the close buttons
// $alert-close-color: $oil;
// $alert-close-top: 50%;
// $alert-close-position: rem-calc(4);
// $alert-close-font-size: rem-calc(22);
// $alert-close-opacity: 0.3;
// $alert-close-opacity-hover: 0.5;
// $alert-close-padding: 9px 6px 4px;

// We use this to control border radius
// $alert-radius: $global-radius;

// We use this to control transition effects
// $alert-transition-speed: 300ms;
// $alert-transition-ease: ease-out;


//
// SWITCH
//


// Controlling border styles and background colors for the switch container
// $switch-border-color: scale-color($white, $lightness: -20%);
// $switch-border-style: solid;
// $switch-border-width: 1px;
$switch-bg: $secondary-color;
$switch-active-color: $success-color;

// We use these to control the switch heights for our default classes
// $switch-height-tny: rem-calc(22);
// $switch-height-sml: rem-calc(28);
// $switch-height-med: rem-calc(36);
// $switch-height-lrg: rem-calc(44);
// $switch-bottom-margin: rem-calc(20);

// We use these to control default font sizes for our classes.
// $switch-font-size-tny: 11px;
// $switch-font-size-sml: 12px;
// $switch-font-size-med: 14px;
// $switch-font-size-lrg: 17px;
// $switch-label-side-padding: 6px;

// We use these to style the switch-paddle
// $switch-paddle-bg: $white;
// $switch-paddle-fade-to-color: scale-color($switch-paddle-bg, $lightness: -10%);
// $switch-paddle-border-color: scale-color($switch-paddle-bg, $lightness: -35%);
// $switch-paddle-border-width: 1px;
// $switch-paddle-border-style: solid;
// $switch-paddle-transition-speed: .1s;
// $switch-paddle-transition-ease: ease-out;
// $switch-positive-color: scale-color($success-color, $lightness: 94%);
// $switch-negative-color: $white-smoke;

// Outline Style for tabbing through switches
// $switch-label-outline: 1px dotted $jumbo;

//
// 04. Breadcrumbs
// - - - - - - - - - - - - - - - - - - - - - - - - -

// $include-html-nav-classes: $include-html-classes;

// We use this to set the background color for the breadcrumb container.
// $crumb-bg: scale-color($secondary-color, $lightness: 55%);

// We use these to set the padding around the breadcrumbs.
// $crumb-padding: rem-calc(9 14 9);
// $crumb-side-padding: rem-calc(12);

// We use these to control border styles.
// $crumb-function-factor: -10%;
// $crumb-border-size: 1px;
// $crumb-border-style: solid;
// $crumb-border-color: scale-color($crumb-bg, $lightness: $crumb-function-factor);
// $crumb-radius: $global-radius;

// We use these to set various text styles for breadcrumbs.
//$crumb-font-size: rem-calc(12);
// $crumb-font-color: $primary-color;
// $crumb-font-color-current: $black;
// $crumb-font-color-unavailable: $aluminum;
// $crumb-font-transform: uppercase;
// $crumb-link-decor: underline;

// We use these to control the slash between breadcrumbs
// $crumb-slash-color: $base;
// $crumb-slash: "/";

// 05. Buttons
// - - - - - - - - - - - - - - - - - - - - - - - - -

// $include-html-button-classes: $include-html-classes;

// We use these to build padding for buttons.
// $button-tny: rem-calc(10);
// $button-sml: rem-calc(14);
// $button-med: rem-calc(16);
// $button-lrg: rem-calc(18);

// We use this to control the display property.
// $button-display: inline-block;
// $button-margin-bottom: rem-calc(20);

// We use these to control button text styles.
// $button-font-family: $body-font-family;
// $button-font-color: $white;
// $button-font-color-alt: $oil;
// $button-font-tny: rem-calc(11);
// $button-font-sml: rem-calc(13);
// $button-font-med: rem-calc(16);
// $button-font-lrg: rem-calc(20);
// $button-font-weight: $font-weight-normal;
// $button-font-align: center;

// We use these to control various hover effects.
// $button-function-factor: -20%;

// We use these to control button border and hover styles.
// $button-border-width: 0px;
// $button-border-style: solid;
// $button-bg-color: $primary-color;
// $button-bg-hover: scale-color($button-bg-color, $lightness: $button-function-factor);
// $button-border-color: $button-bg-hover;
// $secondary-button-bg-hover: scale-color($secondary-color, $lightness: $button-function-factor);
// $secondary-button-border-color: $secondary-button-bg-hover;
// $success-button-bg-hover: scale-color($success-color, $lightness: $button-function-factor);
// $success-button-border-color: $success-button-bg-hover;
// $alert-button-bg-hover: scale-color($alert-color, $lightness: $button-function-factor);
// $alert-button-border-color: $alert-button-bg-hover;

// We use this to set the default radius used throughout the core.
// $button-radius: $global-radius;
// $button-round: $global-rounded;

// We use this to set default opacity and cursor for disabled buttons.
// $button-disabled-opacity: 0.7;
// $button-disabled-cursor: $cursor-default-value;

////////////////////////////////
//
// CHARTIST SETTINGS OVERRIDES
//
////////////////////////////////


$ct-series-names: (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y, z) !default;

$ct-series-colors: (
        #8ede43,
        #F6BD0F,
        #AFD8F8,
        #8BBA00,
        #008E8E,
        #FF8E46,
        #D64646,
        #8E468E,
        #588526,
        #B3AA00,
        #008ED6,
        #9D080D,
        #A186BE,
        #9A652F,
        #A8CB3E,
        #F9CE4B,
        #C5E1F6,
        #F8AE55,
        #407FBC,
        #FBEF4A,
        #F5919E,
        #C1B93F,
        #FAD1A3,
        #B8A4CE,
        #B5474A,
        #3FA9E0,
        #CECF9A,
        #82A35B,
        #AA74AA,
        #E07474,
        #40AAAA,
        #D0499A,
        #76C9B6,
        #6566AE,
        #CE6728,
        #CDDC29,
        #9A652F,
        #cbb72f,
        #e31c00,
        #93ddcf,
        #a26500,
        #007c2e,
        #ea305c,
        #c4338e,
        #533881,
        #7a6a1a,
        #9c3100,
        #00ba82,
        #90005f,
        #7179a8,
        #8d212e,
        #ba842c,
        #e54a35,
        #a8d9cf,
        #e33f4e,
        #2fac9a,
        #e76934,
        #dc77c4,
        #b1562e,
        #dc77c4,
        #b1562e,
        #e08891,
        #8c93b6,
        #a5367e,
        #2cce9f,
        #9e37be,
        #61b46c,
        #9e37be,
        #61b46c,
        #9e37be,
        #61b46c,
        #53829c,
        #be1841,
        #cc6a18,
        #8d212e,
        #caefa7,
        #fbe08e,
        #afd8f8,
        #c8df87,
        #87caca,
        #ffcaa8,
        #eca8a8,
        #caa8ca,
        #b0c699,
        #dbd787,
        #87caec,
        #d18b8d,
        #d3c6e0,
        #cfb79d,
        #d6e7a4,
        #fce8aa,
        #e4f1fb,
        #fcd9af,
        #a5c3df,
        #fdf7aa,
        #facbd1,
        #e2dea5,
        #fde9d4,
        #ded4e8,
        #dca8aa,
        #a5d7f0,
        #e8e8cf,
        #c4d4b2,
        #d7bed7,
        #f0bebe,
        #a5d7d7,
        #e8e8cf
) !default;
