$transition: 0.2s ease all;


select {
    padding:0.5rem 1.0rem 0.5rem 0.5rem;
}
/* For login page buttons to set height same as input */
button.login-go {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}


input[type="checkbox"], input[type="radio"] {
  height: 1.0rem;
  width: 1.0rem;
}
input[type="checkbox"] + label,
input[type="radio"] + label {
  vertical-align: text-top;
}
.input-group {
  position: relative;
  background: #ffffff;
  margin-bottom: 10px;
  border: 1px solid #cccccc;

  .disabled {
    background: #DDD;
  }

  label {
    color: #999999;
    font-size: 14px;
    font-weight: normal;
    height: 14px;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 8px;
    transition: $transition;
    -moz-transition: $transition;
    -webkit-transition: $transition;
  }
}

label.subheader {
  font-family: $header-font-family;
  font-size: $h5-font-size;
  color: $header-font-color;

  > span {
    font-size: 0.8rem;
  }
}

.inputMaterial {
  font-size: 14px !important;
  display: block;
  border: none !important;
  padding: 1px 5px 2px 5px !important;
  margin-bottom: 0px !important;
  background: none;
  transition: $transition;
  -moz-transition: $transition;
  -webkit-transition: $transition;
}

.inputMaterial:focus {
  outline: none;
}

/* active state */
.inputMaterial:valid {
  padding: 14px 5px 2px 5px !important;
}

.inputMaterial:valid ~ label {
  top: 1px;
  font-size: 12px;
  color: $primary-color;
}

.prefix {
  min-height: 2.3125rem;
  height: 100% !important;
}

.postfix {
  min-height: 2.3125rem;
  height: 100% !important;
}

.no-bot-margin {margin-bottom:0 !important;}
