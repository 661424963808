@import "../components/global";

.footer {
  /*color: darken($secondary-color, 6);*/
  padding-top: $panel-padding;
  font-size: 0.8rem;

  .official-provider {
    margin-bottom: 1.0rem;

    p.provider-text {
      font-size: 0.9rem;
      line-height: 1.4;

      @media #{$medium-up} {
        margin-top: 2.0rem;
      }
    }
    img {max-width:120px;}
  }

  p {
    font-size: 0.8rem;
  }

  a {
    /*color: darken($secondary-color, 6);*/
    text-decoration: underline;

    &:hover {
      /*color: darken($info-color, 25%);*/
    }
  }
}
