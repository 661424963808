//prefix export name with "app" since foundation already exports a "global" which would override this
@include exports("appGlobal") {

  .fullWidth {
    width: 100%;
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0.25rem;
  }

  .content {
    padding: $panel-padding 0;
    background: $white;
  }

  .vertDivide {
    border-right: 1px solid $secondary-color;
  }

  .invisible {
    visibility: hidden;
  }

  .highlight {
    &:hover {
      -webkit-box-shadow: 0 0 6px 1px rgba(45,142,251,.5);
      box-shadow: 0 0 6px 1px rgba(45,142,251,.5);
    }
  }

  .noMargin {
    margin: 0 !important;
  }

  .noPadding {
    padding: 0 !important;
  }

  .noPaddingTop {
    padding-top: 0 !important;
  }

  .noPaddingRight {
    padding-right: 0 !important;
  }

  .noPaddingBottom {
    padding-bottom: 0 !important;
  }

  .noPaddingLeft {
    padding-left: 0 !important;
  }

  .noLeftRightPadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .noBorder {
    border: 0 !important;
  }

  .noMarginTop {
    margin-top: 0 !important;
  }

  .noMarginRight {
    margin-right: 0 !important;
  }

  .noMarginBottom {
    margin-bottom: 0 !important;
  }

  .noMarginLeft {
    margin-left: 0 !important;
  }

  .vcard {
    word-break: break-all;

    button.tiny {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    .email a {
      text-decoration: underline;
    }
  }

  .lead-rating {
    color: darken($secondary-color, 20);

    li {
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 1.0rem;
      }
    }

    .star-rating {
      color: #fdb525;
    }
  }

  .fa-star.active {
    color: #fdb525;
  }

  .underline {
    text-decoration: underline;
  }

  .survey {
    padding-top: rem-calc(10);
  }

  .pointer {
    cursor: pointer;
  }

  [ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
  }

  .greyscale {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .f-dropdown li.active {
    background-color: #EEEEEE;
  }
}
