@import "../components/global";

.header {
  @media #{$large-up} {
    padding-top: 5px;
    }
    background-color: $topbar-bg-color;
    border-color: $topbar-bg-color;
    color: $topbar-link-color;
    margin-bottom: 0;
  }

  .header:not(.panel) img {
    max-width: 10rem;
  }

  .header .top-bar .toggle-topbar {
    //left: 0;
  }

  .header .top-bar-section li:not(.has-form) a.welcome:not(.button):hover {
    background: inherit;
    cursor: inherit;
  }

.header .top-bar-section li:not(.has-form) a:not(.button).active {
  background: $dark-blue-background;
  color: $white;

  @media #{$large-up} {
    border-radius: $global-radius $global-radius 0 0;
  }
}

  .header .top-bar-section li:not(.has-form) a.welcome:not(.button) {
    padding: 0;
  }

  .header .top-bar .toggle-topbar.menu-icon a {
    padding: 0 20px 0 15px;
  }

.header .top-bar .title-area .name h1 {
    text-align:left;

    img {
    vertical-align: baseline;
    height:44px;
  }
}
