/* ----------------------------------- */
/*  default OneTrust template styles   */
/* ----------------------------------- */
#onetrust-consent-sdk #onetrust-pc-btn-handler, #onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
    color: #134f97;
    border-color: #134f97;
    background-color: #FFFFFF;
}

#onetrust-consent-sdk #onetrust-accept-btn-handler, #onetrust-banner-sdk #onetrust-reject-all-handler {
    background-color: #134f97;
    border-color: #134f97;
    color: #FFFFFF;
}

#onetrust-consent-sdk #onetrust-pc-sdk button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(.ot-obj-leg-btn-handler):not([aria-expanded]):not(.ot-link-btn), #onetrust-consent-sdk #onetrust-pc-sdk .ot-leg-btn-container .ot-active-leg-btn {
    background-color: #134f97;
    border-color: #134f97;
    color: #FFFFFF;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
    background-color: #d5e9ff;
    border: 1px solid #3860be;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob::before {
    background-color: #3860be;
}


/* ----------------------------------- */
/* custom OneTrust style */
/* ----------------------------------- */

/* Thor Hammer Method */
#onetrust-pc-sdk *, #onetrust-banner-sdk * {font-size:0.8rem !important;}
