.page-feedback {background-color:#f7f7f7;padding:1.0rem 0;}

#exfb-content {
  font-size: 0.8rem;

  .exfb-question-element .button, .exfb-comment-element .button {
    font-size: 0.9rem;
    margin: 0 0.25rem 0 0;
    padding: 0.375rem 1.0rem;
    background-color:$secondary-color;
    color:$body-font-color;
    border-radius: $global-radius;

    &:hover {background-color:lighten($secondary-color, 20%);}
  } 

  .exfb-question-element {
    display: inline-block;
    margin-right: 0.5rem;
  }
}
